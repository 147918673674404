import { tasksSlice } from "./tasksSlice";
import * as defaultActions from "../../../../../redux/_common";
import * as constants from "./constants";
import * as fields from "../../pages/tasks/preview/constants";
import * as surveyFields from '../../../MasterData/pages/surveys/edit/constants'
import {
  getCurrentUserOrganizationUnit,
  getCurrentAccessToken,
} from "../../../../user-access/organizationUnitStorage";
import { swaggerClient } from "../../../../../api/swaggerApiConnect";
import { Dispatch } from "@reduxjs/toolkit";
import { ITaskDetails, ITaskEdit, ITaskOrigin, ISurveyDetails, ITaskOrderDetails, IRejectionReasons, ITaskNote, IQuestionAnswer, ISurveySubmissionQuestion, ISurveySavedQuestionFile, ISurveySub } from "../interface";
import { IQueryParams, IMasterDataTaskCustomers } from "../../../../common/interfaces/API";
import { getErrorMessage } from "../../../../../redux/_helpers";
import { get, post, put } from "../../../../../api/requester";
import { ICustomer } from "../../../../common/interfaces/Customer";
import { constructInitialFilters } from "../../../../components/common/filters/utils";
import { format } from "date-fns";
import { ICustomerContactPersons, ITaskCategory } from "../../../Dashboard/_interfaces/reduxState";

export const moduleUrl = '/api/activity/tasks';
const { actions } = tasksSlice;

export const setValidationErrors = actions.setValidationErrors;

export const getItems = (params:IQueryParams) => defaultActions.getItems(moduleUrl, actions, params);
export const getItem = (id: string) => defaultActions.getItem(`${moduleUrl}/${id}`, actions);
export const setTaskDetails = actions.setTaskDetails
export const getTaskDetails = (id: string) => async (dispatch:Dispatch) => {
  try {
    const res = await get(`${moduleUrl}/taskDetails`, {id});
    dispatch(actions.setTaskDetails({
      ...res,
      plannedFrom: format(new Date(res.plannedFrom), 'dd/MM/yyyy HH:mm'),
      plannedTo: format(new Date(res.plannedTo), 'dd/MM/yyyy HH:mm'),
      createdOn: format(new Date(res.createdOn), 'dd/MM/yyyy HH:mm'),
      lastChangeDate: format(new Date(res.lastChangeDate), 'dd/MM/yyyy HH:mm'),
      customers: {
        participants: res.participants.map((p:any) => ({
          ...p,
          disableDelete: true,
        }))
      },
      [fields.TASK_FILES]: res[fields.TASK_FILES].map((file:any) => ({
        ...file,
        id: file.taskCustomerFileId || file.id,
        originalFileName: file.originalFileName || file.url.split('/')[file.url.split('/').length - 1]
      })),
      [fields.TASK_NOTES]: res.taskNotes.map((note:ITaskNote) => ({
        ...note,
        createdOn: format(new Date(note.createdOn), 'dd/MM/yyyy HH:mm'),
      }))
    }));
  } catch (errors:any) {
    const error = getErrorMessage(errors, "Error occured during fetching task details!");
    dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
    dispatch(actions.setCustomersLoading(false));
  }
}

export const updateTask = (item:ITaskDetails, id:string, submit:boolean = false) => async (dispatch:Dispatch) => {
  try {
    const createNotes = item.taskNotes.reduce((notes:{notes:string}[], note:ITaskNote) => [
      ...notes,
      ...(note.created ? [{notes: note.notes}] : []),
    ], []);

    const updateNotes = item.taskNotes.reduce((notes:{id:string, notes:string}[], note:ITaskNote) => [
      ...notes,
      ...(note.updated ? [{ id: note.id, notes: note.notes }] : []),
    ], []);

    const deleteNotes = item[fields.TASK_NOTES].reduce((notes:{id:string}[], note:ITaskNote) => [
      ...notes,
      ...(note.deleted && !note.created ? [{ id: note.id }] : []),
    ], []);

    const files:File[] = [];
    const createFiles:{fileName:string}[] = [];
    item[fields.TASK_FILES].forEach(a => {
      if (a?.action === "create") {
        a.file && files.push(a.file);
        createFiles.push({ fileName: a.file?.name || "n/a" });
      }
    });

    const setQuestionFiles = (question:ISurveySubmissionQuestion | ISurveySub) => {
      const surveyFiles:{
        fileName: string;
      }[] = []

      if (question.createAttachments) {
        question.createAttachments.forEach((attachment) => {
          if (attachment.action === 'create' || attachment.action === 'update') {
            surveyFiles.push({ fileName: attachment.fileName })
            attachment.file && files.push(attachment.file);
          }
        });

        return { createAttachments: surveyFiles }
      }
      
      return {}
    }

    const form = {
      submit,
      ...(item[fields.SURVEY_SUBMISSION] ? {
        [fields.SURVEY_SUBMISSION]: {
          surveys: item[fields.SURVEY_SUBMISSION].map((survey) => {
            return {
              id: survey.id,
              ...setQuestionFiles(survey),
              deleteAttachmentsIds: survey.deleteAttachmentsIds,
              sections: survey.sections.map((section) => ({
                id: section.id,
                questions: section.questions.map((question) => ({
                  surveyQuestionId: question.id,
                  ...setQuestionFiles(question),
                  ...(typeof question.surveyAnswerValues === 'string'
                      ? {value: question.surveyAnswerValues}
                      : question.typeCode === surveyFields.Single_code
                        ? {answerValueId: question.surveyAnswerValues?.find((val) => val.isChecked)?.id}
                        : {answerValuesIds: question.surveyAnswerValues?.reduce((a:string[], e) => [
                          ...a,
                          ...(e.isChecked ? [e.id] : [])
                        ], []) || question.surveyAnswerValues}
                  ),
                  taskCustomerId: item[fields.TASK_CUSTOMER_ID],
                  deleteAttachmentsIds: question.deleteAttachmentsIds,
                }))
              }))
            }
          })
        },
      } : {}),
      id: item[fields.TASK_CUSTOMER_ID],
      createParticipants: item[constants.CUSTOMERS][constants.PARTICIPANTS].filter((contact) => contact.created),
      createNotes,
      updateNotes,
      deleteNotes,
      createFiles,
      deleteFiles: item[fields.DELETE_TASK_FILES] || [],
    }
    
    const formData = new FormData();
    formData.append('command', JSON.stringify(form));
    files.forEach((file) => formData.append('files', file));

    await put(moduleUrl, {}, formData, { contentType: 'multipart/form-data' });
    return true;
  } catch (errors:any) {
    const error = getErrorMessage(errors, "Error occured during task update!");
    let title = ''
    if (error.indexOf('Please provide an answer to all mandatory questions in order to be able to submit.') >= 0) {
      title = 'Mandatory question not answered.'
    }
    dispatch(actions.catchError({
      errors: error,
      dialogTitle: title,
      callType: "actionsLoading",
      errorProp: "errorAction",
      errorTitle: 'errorTitle',
    }));
    actions.setCustomersLoading(false);
  }

  return false;
}

export const clearAll = () => defaultActions.clearAll(actions);
export const clearErrors = () => defaultActions.clearErrors(actions);
export const clearTaskDetails = () => async (dispatch: Dispatch) => {
  dispatch(actions.clearTaskDetails())
};

export const saveItem = (item:ITaskEdit, id: string | null) =>
  defaultActions.saveItem(
    moduleUrl,
    actions,
    item,
    id,
    refineDataForSaving,
    'multipart/form-data'
  );

const refineDataForSaving = (item:ITaskEdit) => {
  const form = {
    taskTypeId: item.taskType.id,
    notes: item.notes,
    territoryAssignmentId: item.territoryAssignment,
    plannedFromUTC: item.plannedFromUTC,
    plannedToUTC: item.plannedToUTC,
    description: item.description,
    origin: item.origin,
    ...(constants.TASK_TYPE_CUSTOMER_DEV.indexOf(item.taskType.code) >= 0 ? {
      sendMeetingInvitation: item.sendMeetingInvitation,
      customerDevelopmentTypeId: item.customerDevelopmentTypeId?.id,
    } : {}),
    customers: item.customers.map((c) => ({
      customerId: c.customerId,
      ...(c.equipmentId ? {equipmentId: c.equipmentId.id} : {}),
      ...(c.participants
          ? {participants: c.participants.map((p) => ({
            participantType: p.participantType,
            name: p.name,
            email: p.email,
          }))}
          : {}),
    })),
    taskCustomerFiles: (item.taskCustomerFiles || []).map((f) => ({fileName: f.fileName})),
    taskMaintenances: item.taskMaintenances?.map((v) => ({ malfunctionId: v.id })),
    surveys: item.surveys?.map(
      survey =>
        ({
          surveyId: survey.id,
          isMandatory: true,
        }),
    ) ?? [],
  }
  
  const formData = new FormData();
  formData.append('command', JSON.stringify(form));
  
  item.taskCustomerFiles?.forEach((f) => {
    formData.append('files', f.file);
  });

  return formData;
}

export const rejectTasks = (data:{ taskCustomerIds:string[], rejectionReasonId:string }) => async (dispatch:Dispatch) => {
  try {
    await post('/api/activity/tasks/reject', {}, data);
    return true;
  } catch (errors:any) {
    const error = getErrorMessage(errors, "Error occured during rejecting tasks!");
    dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
    actions.setCustomersLoading(false);
    return false;
  }
}

export const fetchOriginSwagger = async (dispatch:Dispatch) => {
  const response = await getCurrentAccessToken();
  const userOrgUnit = getCurrentUserOrganizationUnit();

  const params = {
    Code: constants.TASK_ORIGIN_CODE,
    OrganizationUnit: userOrgUnit,
  };

  if (swaggerClient) {
    return swaggerClient.then((client:any) =>
      client
        .execute({
          spec: client.spec,
          operationId: "TaskOrigins_GetByCode",
          parameters: params,
          securities: {
            authorized: {
              bearerAuth: response?.accessToken,
            },
          },
          responseContentType: "application/json",
        })
        .then((data:any) => {
          const item:ITaskOrigin = data.body;
          dispatch(actions.taskOriginFetched(item));
        })
        .catch((errors:any) => {
          const error = getErrorMessage(errors, "Error occured during fetched task origin!");
          dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
        }),
    );
  }
};

export const resetTerritoryAssignmentCustomers = () => (dispatch:Dispatch) => {
  dispatch(actions.resetTerritoryAssignmentCustomers())
}

export const fetchTerritoryAssignmentCustomers = (params:IMasterDataTaskCustomers) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setCustomersLoading(true));
    const customers:ICustomer[] = await get('/api/appmasterdata/customers/taskCustomers', params);
    dispatch(actions.setCustomers(customers));
  } catch (errors:any) {
    const error = getErrorMessage(errors, "Error occured during fetched task origin!");
    dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
  } finally {
    actions.setCustomersLoading(false);
  }
}

export const setSurveys = (surveys:ISurveyDetails[]) => actions.setSurveys(surveys)

export const fetchTaskSurveys = (id:string, fetchForEdit = false) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setSurveys([]));
    dispatch(actions.setSurveysLoading(true));
    const surveyDetails:ISurveyDetails[] = await get(`${moduleUrl}/taskSurveysDetails`, {TaskLogId: id});

    if (fetchForEdit) {
      let surveys:any[] = [];
      for (const detail of surveyDetails) {
        let survey:ISurveyDetails = await get(`/api/activity/surveys/${detail.id}`);

        survey.createAttachments = (detail.surveyFiles || []).map((f) => ({
          id: f.id,
          fileUrl: f.url,
          url: f.url,
          mimeType: f.mimeType,
          fileName: f.originalFileName,
          originalFileName: f.originalFileName,
          action: "old",
        }));

        detail.sections.forEach((detailSection) => {
          let surveySection = survey.sections.find((section) => section.id === detailSection.id)

          detailSection.questions.forEach((question) => {
            let surveyQuestion:any = surveySection?.questions.find((sQuestion) => sQuestion.id === question.id);
            const { surveyAnsweredValue, surveyAnsweredValues, surveyAnswerTypeCode, defaultValue } = question;

            if (surveyQuestion) {
              if (question.questionFiles) {
                surveyQuestion.createAttachments = question.questionFiles.map((f:ISurveySavedQuestionFile) => ({
                  id: f.id,
                  fileUrl: f.url,
                  url: f.url,
                  mimeType: f.mimeType,
                  fileName: f.originalFileName,
                  originalFileName: f.originalFileName,
                  action: "old",
                }))
              }
              

              if (surveyAnswerTypeCode !== surveyFields.Single_code && surveyAnswerTypeCode !== surveyFields.Multi_code) {
                if (surveyAnswerTypeCode === surveyFields.Time_code && surveyAnsweredValue && surveyAnsweredValue.length < 8) {
                  const times = surveyAnsweredValue.split(':');
                  const newValue = new Date().setHours(Number(times[0]), Number(times[1]));
                  surveyQuestion.surveyAnswerValues = new Date(newValue).toISOString() || defaultValue;
                  surveyQuestion.surveyAnsweredValue = new Date(newValue).toISOString() || defaultValue;
                  surveyQuestion.defaultValue = defaultValue;
                } else {
                  surveyQuestion.surveyAnswerValues = surveyAnsweredValue || defaultValue;
                  surveyQuestion.surveyAnsweredValue = surveyAnsweredValue || defaultValue;
                  surveyQuestion.defaultValue = defaultValue;
                }
              } else {
                //Check if user has selected something and if not check for default values;
                if (surveyAnsweredValues.length === 0) {
                  surveyQuestion.surveyAnswerValues.forEach((answer:IQuestionAnswer, i:number) => {
                    if (answer.isSelected) {
                      surveyQuestion.defaultValue = [
                        ...(surveyQuestion.defaultValue || []),
                        {
                          ...surveyQuestion.surveyAnswerValues[i],
                          isChecked: true,
                          isSelected: true
                        }
                      ]
                      surveyQuestion.surveyAnswerValues[i] = {
                        ...surveyQuestion.surveyAnswerValues[i],
                        isChecked: true,
                        isSelected: true
                      }
                    }
                  })
                } else {
                  surveyQuestion.surveyAnswerValues.forEach((answer:IQuestionAnswer, i:number) => {
                    surveyQuestion.surveyAnswerValues[i] = {
                      ...surveyQuestion.surveyAnswerValues[i],
                      isChecked: false,
                      isSelected: false
                    }
                    if (surveyAnsweredValues.indexOf(answer.value) >= 0) {
                      surveyQuestion.defaultValue = [
                        ...(surveyQuestion.defaultValue || []),
                        {
                          ...surveyQuestion.surveyAnswerValues[i],
                          isChecked: true,
                          isSelected: true
                        }
                      ]
                      surveyQuestion.surveyAnswerValues[i] = {
                        ...surveyQuestion.surveyAnswerValues[i],
                        isChecked: true,
                        isSelected: true
                      }
                    }
                  });
                }
              }
            }
          });
        });
        
        surveys = [
          ...surveys,
          survey
        ];
      }

      dispatch(actions.setSurveys(surveys));
    } else {
      dispatch(actions.setSurveys(surveyDetails));
    }

  } catch (errors:any) {
    const error = getErrorMessage(errors, "Error occured during fetching surveys!");
    dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
    dispatch(actions.setSurveysLoading(false));
    dispatch(actions.setSurveys([]));
  }
}

export const fetchTaskOrderDetails = (TaskLogId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.setTaskOrderDetailsLoading(true));
    const res: ITaskOrderDetails = await get(`${moduleUrl}/taskOrdersDetails`, {TaskLogId})
    dispatch(actions.setTaskOrderDetails(res));
  } catch (errors: any) {
    const error = getErrorMessage(errors, "Error occured during fetching surveys!");
    dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
    dispatch(actions.setSurveysLoading(false));
    dispatch(actions.setTaskOrderDetailsClear());
  }
};

export const fetchRejectionReasons = () => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setRejectionReasonsLoading(true));
    const res = await get('/api/activity/rejectionreasons');
    dispatch(actions.setRejectionReasons(res.items.filter(({isActive}:IRejectionReasons) => isActive)));
  } catch (errors:any) {
    const error = getErrorMessage(errors, "Error occured during fetching rejection reasons!");
    dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
    dispatch(actions.setSurveysLoading(false));
  }
}


export const getTaskCategories = () => async (dispatch: Dispatch) => {
  try {
    const res: ITaskCategory[] = await get('/api/activity/taskcategories/select')
    dispatch(actions.setTaskCategories(res));
  } catch (errors: any) {
    const error = getErrorMessage(errors, "Error occured during fetching task categories!");
    dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
    dispatch(actions.setSurveysLoading(false));
  }
};

export const getContactPersonsForCustomer = (CustomerId:string) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setCustomerContactPersons([]))
    const res:ICustomerContactPersons[] = await get('/api/appmasterdata/customers/contactPersons', {CustomerId})
    dispatch(actions.setCustomerContactPersons(res))
  } catch (errors: any) {
    const error = getErrorMessage(errors, "Error occured during fetching customer contact persons!");
    dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
  }
}


export const getContactPersonsForContact = (params:any) => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setCustomerDropdownLoading(true))
    const res:ICustomerContactPersons[] = await get('/api/usersmanagement/users/forContact', params.filter ? {Filter: params.filter} : {});
    dispatch(actions.setCustomerDropdown(res.map((item) => ({...item, isActive: true}))))
  } catch (errors: any) {
    const error = getErrorMessage(errors, "Error occured during fetching customer contact persons!");
    dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
  } finally {
    dispatch(actions.setCustomerDropdownLoading(false));
  }
}

export const getCustomerDevelopmentTypes = () => async (dispatch:Dispatch) => {
  try {
    dispatch(actions.setCustomerDevelopmentTypesLoading(true));
    const res = await get('/api/activity/tasks/customerDevelopmentTypes/select')
    dispatch(actions.setCustomerDevelopmentTypes(res))

  } catch (errors: any) {
    const error = getErrorMessage(errors, "Error occured during fetching customer development types!");
    dispatch(actions.catchError({ errors: error, callType: "actionsLoading", errorProp: "errorAction" }));
  } finally {
    dispatch(actions.setCustomerDevelopmentTypesLoading(false));
  }
}

export const getFilters = () => async (dispatch:Dispatch) => {
  try {
    const taskTypes:any[] = await get('/api/activity/tasktypes/complexSearch');
    const taskStatuses:any[] = await get("/api/activity/taskstatuses/complexSearch");
    const taskCategories:ITaskCategory[] = await get("/api/activity/taskcategories/complexSearch");

    dispatch(actions.setFilter(
        constructInitialFilters([
          ...(taskTypes || []),
          ...(taskStatuses || []),
          ...(taskCategories || []),
        ])
    ));
    
  } catch (errors) {
    const message = getErrorMessage(errors, "Error occurred!");
    dispatch(actions.catchError({ errors: message, callType: "actionsLoading", errorProp: "errorAction" }));
  }
};
export const setFilter = (filters: any[]) => defaultActions.setFilters(filters, actions);